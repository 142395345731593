<template>
  <div>
    <div class="row mb-2">
      <div class="col-2">
        <template v-if="crew[imgGetter]">
          <img :src="crew[imgGetter]" :alt="crew.last_name +' Image'" style="width: 175px;">
        </template>
        <template v-else>
          <img :src="require('../../../../public/img/crew.png')" :alt="crew.last_name +' Image'" style="width: 175px;">
        </template>
      </div>
      <div class="col-9">
        <h6 class="font-weight-bolder">
          {{ crew.last_name }}, {{ crew.first_name }} {{ crew.middle_name ? crew.middle_name.substring(0, 1) + '.' : '' }}
          <template v-if="crew.rank || crew.ipn">
            <template>({{crew.rank.toUpperCase()}} , {{crew.ipn.toUpperCase()}})</template>
          </template>
        </h6>
        <div class="row">
          <div class="col-auto">
            MANNING: <strong>{{ crew.manning ? crew.manning.toUpperCase() : '' }}</strong>
          </div>
          <div class="col-auto">
            LAST VESSEL: <strong>{{ crew.last_vessel ? crew.last_vessel.vesselName.toUpperCase() : '' }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            NATIONALITY: <strong>{{ crew.nationality ? crew.nationality.toUpperCase() : '' }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <template v-if="crew.noOfExperiencesCurrentRank">
              <img :src="require('../../../../public/img/current-exp-vessel.png')" v-for="num in crew.noOfExperiencesCurrentRank" class="d-inline-block" alt="Current Rank Vessels" style="width: 20px;">
            </template>
            <strong class="ml-1">{{crew.currentRankDuration ? crew.currentRankDuration.toUpperCase() : ''}}</strong>
          </div>

        </div>
        <div class="row">
          <div class="col-auto">
            <template v-if="crew.noOfExperiences">
              <img :src="require('../../../../public/img/all-exp-vessel.png')" v-for="num in crew.noOfExperiences" class="d-inline-block" alt="Current Rank Vessels" style="width: 20px;">
            </template>
            <strong class="ml-1">{{crew.allExpDuration ? crew.allExpDuration.toUpperCase() : ''}}</strong>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import allExpImg from '../../../../public/img/all-exp-vessel.png'
// import currentExpImg from '../../../../public/img/current-exp-vessel.png'
// import crewNoImg from '../../../../public/img/crew.png'

export default {
  name: "ExperienceUi",
  props: {
    crew: {
      type: Object | Array,
      default: null
    },
    imgGetter: {
      type: String,
      default: 'crewImage'
    }
  }
}
</script>

<style scoped>

</style>
